import * as React from 'react'
import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import { OpenInNewRounded } from '@mui/icons-material'
import { useRecoilValue } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'

const ServicesList = {
    AWS: [
        {
            title: 'EC2',
            description: 'Manage compute instances',
            url: '/services/aws/ec2'
        },
        {
            title: 'EMR',
            description: 'Manage Elastic Map Reduce clusters',
            url: '/services/aws/ec2'
        },
    ],
    Azure: [
        {
            title: 'VMs',
            description: 'Manage VMs',
            url: '/services/aws/ec2'
        }
    ]
}

const initialPermissionsState = {
    finops: false,
    devops: false
}

const Navigation = (props) => {

    const theme = useTheme()
    const [permissions,setPermissions] = React.useState(initialPermissionsState)
    const userState = useRecoilValue(ApplicationUserState)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorElServices, setAnchorElServices] = React.useState(null)
    let navigate = useNavigate()
    const [selectedCloudService, setSelectedCloudService] = React.useState('')
    const [selectedCloudServiceList, setSelectedCloudServiceList] = React.useState([])

    React.useEffect(() => {

        if(userState) {

            const roles = userState.role
            const computedPermissions = {...initialPermissionsState}
            
            //Check for finops permission dashboard-viewer
            computedPermissions.finops = roles.includes('dashboard-viewer')

            //Check for devops permission services-viewer
            computedPermissions.devops = roles.includes('services-viewer')

            setPermissions(computedPermissions)

        } else {

            setPermissions(initialPermissionsState)

        }

    },[userState])

    React.useEffect(() => {

        if(selectedCloudService != '') {

            setSelectedCloudServiceList(ServicesList[selectedCloudService])

        }

    }, [selectedCloudService])

    const handleNavClick = (type) => event => {

        if(type === 'dashboard') {

            navigate('/dashboard/executive-summary/aws', { replace: false })

        } else if(type === 'services') {

            handleServicesMenuOpen(event)

        }

    }

    const handleServiceClick = (service) => {

        setSelectedCloudService(service)

    }

    const handleServicesMenuOpen = (event) => {

        setAnchorElServices(event.currentTarget)

    }

    const handleSerivesMenuClose = () => {

        setAnchorElServices(null)

    }

    return (
        <React.Fragment>
            <Box sx={{flexGrow: 1, display: 'flex', ml: 4, gap: 4}}>
                {
                    permissions.finops && <Button onClick={handleNavClick('dashboard')} variant="text" sx={{color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main, fontSize: '0.85rem'}}>FinOps Dashboard</Button>
                }
                {
                    permissions.devops && <Button onClick={handleNavClick('services')} variant="text" sx={{color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main, fontSize: '0.85rem'}}>DevOps Services</Button>
                }
                <Popover
                    id="services-menu-popover"
                    open={Boolean(anchorElServices)}
                    anchorEl={anchorElServices}
                    onClose={handleSerivesMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <Box sx={{height: '50vh', aspectRatio: 1.75}}>
                        <Grid sx={{width: '100%', height: '100%'}} container alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                            <Grid sx={{height: '100%', borderRight: `1px solid ${grey[200]}`}} item xs={4}>
                                <Grid sx={{p:2}} container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" align="center" sx={{fontWeight: '500'}}>Cloud Services</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider variant='fullWidth'></Divider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            <ListItemButton selected={selectedCloudService === 'AWS'} sx={{cursor: 'pointer'}}>
                                                <ListItemText onClick={() => handleServiceClick('AWS')} primary="AWS" />
                                            </ListItemButton>
                                            <ListItemButton selected={selectedCloudService === 'Azure'} sx={{cursor: 'pointer'}}>
                                                <ListItemText onClick={() => handleServiceClick('Azure')} primary="Azure" />
                                            </ListItemButton>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx={{height: '100%', overflowY: 'scroll'}} item xs={8}>
                                {
                                    (Array.isArray(selectedCloudServiceList) && selectedCloudServiceList.length > 0) && (
                                        <List>
                                            {
                                                selectedCloudServiceList.map((service, index) => (
                                                    <React.Fragment>
                                                        <ListItem key={index} secondaryAction={
                                                            <IconButton edge="end" onClick={() => navigate(service.url, { replace: false })}>
                                                                <OpenInNewRounded />
                                                            </IconButton>
                                                        }>
                                                            <ListItemText primary={service.title} secondary={service.description} />
                                                        </ListItem>
                                                        <Divider variant="fullWidth" component="li" />
                                                    </React.Fragment>
                                                ))
                                            }
                                        </List>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Popover>
            </Box>
        </React.Fragment>
    )

}

export default Navigation