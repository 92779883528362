import * as Pages from '../../../../pages'

const ROUTES = [
    {
        link: '/dashboard',
        title: 'Dashboards',
        routeId: 3000,
        roles: ['dashboard-viewer'],
        nested: true,
        component: null,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
        nestedRoutes: [
            {
                link: '/dashboard/executive-summary',
                title: 'Executive Summary',
                routeId: 3001,
                roles: ['dashboard-executive-summary-viewer'],
                nested: true,
                component: null,
                fullPageWidth: true,
                header: false,
                authenticated: true,
                sideNav: true,
                independent: false,
                external: false,
                routing: true,
                nestedRoutes: [
                    {
                        link: '/dashboard/executive-summary/aws',
                        title: 'AWS',
                        routeId: 3001001,
                        roles: ['dashboard-executive-summary-aws-viewer'],
                        nested: false,
                        component: Pages.Dashboard.ExecutiveSummary.AWSPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true
                    },
                ]
            },
            {
                link: '/dashboard/aws',
                title: 'AWS',
                routeId: 3002,
                roles: ['dashboard-aws-viewer'],
                nested: true,
                component: null,
                fullPageWidth: true,
                header: false,
                authenticated: true,
                sideNav: true,
                independent: false,
                external: false,
                routing: true,
                nestedRoutes: [
                    {
                        link: '/dashboard/aws/inventory',
                        title: 'Inventory',
                        routeId: 3002001,
                        roles: ['dashboard-aws-inventory-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.InventoryPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/compute',
                        title: 'Compute',
                        routeId: 3002002,
                        roles: ['dashboard-aws-compute-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.ComputePageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/s3',
                        title: 'S3',
                        routeId: 3002003,
                        roles: ['dashboard-aws-s3-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.S3PageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/rds',
                        title: 'RDS',
                        routeId: 3002004,
                        roles: ['dashboard-aws-rds-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.RDSPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/egress',
                        title: 'Egress',
                        routeId: 3002005,
                        roles: ['dashboard-aws-egress-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.EgressPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/analytics',
                        title: 'Analytics',
                        routeId: 3002006,
                        roles: ['dashboard-aws-analytics-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.AnalyticsPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/lambda',
                        title: 'Lambda',
                        routeId: 3002007,
                        roles: ['dashboard-aws-lambda-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.LambdaPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                    {
                        link: '/dashboard/aws/cloudwatch',
                        title: 'Cloudwatch',
                        routeId: 3002008,
                        roles: ['dashboard-aws-cloudwatch-viewer'],
                        nested: false,
                        component: Pages.Dashboard.AWS.CloudwatchPageContainer,
                        fullPageWidth: true,
                        header: false,
                        authenticated: true,
                        sideNav: true,
                        independent: false,
                        external: false,
                        routing: true,
                    },
                ]
            },
        ]
    }
]

export default ROUTES