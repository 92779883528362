import { atom } from 'recoil'

const ApplicationUserState = atom({
    key: 'ApplicationUserState',
    default: {
        userId: null,
        userName: null,
        customerId: null,
        role: [],
        freeTrial: null
    }
})

const LoginInitializationState = atom({
    key: 'LoginInitializationState',
    default: false
})

export { 
    ApplicationUserState,
    LoginInitializationState
}