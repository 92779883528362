import { AccountCircleRounded, DarkModeRounded, LightModeRounded, LogoutRounded, MenuRounded, OpenInNewRounded, PersonRounded, SettingsRounded, WorkRounded } from '@mui/icons-material'
import { AppBar, Avatar, Box, Button, Divider, Grid, Hidden, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import LogoFullHorizontal from '../../../assets/app-logo/LogoFullHorizontalColor'
import { cyan, grey, orange, teal } from '@mui/material/colors'
import { useRecoilState, useRecoilValue } from 'recoil'
import { NavBarOpenState } from '../../../recoil/navigation/Atom'
import useLogout from '../../../hooks/common/user/useLogout'
import useAppTheme from '../../../hooks/common/theme/useAppTheme'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import SvgLogoFullHorizontalWhite from '../../../assets/app-logo/LogoFullHorizontalWhite'
import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation'

const Header = (props) => {

    const theme = useTheme()
    const [drawerOpen, setDrawerOpen] = useRecoilState(NavBarOpenState)
    const userState = useRecoilValue(ApplicationUserState)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorElServices, setAnchorElServices] = React.useState(null)
    const { logoutUser } = useLogout()
    const { toggleTheme, getCurrentTheme } = useAppTheme()
    let navigate = useNavigate()
    const [selectedCloudService, setSelectedCloudService] = React.useState('')
    const [selectedCloudServiceList, setSelectedCloudServiceList] = React.useState([])

    const handleDrawerOpen = () => {

        setDrawerOpen(true)

    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    //#04064F
    //rgba(255,255,255,0.90)

    return (
        <React.Fragment>
            <AppBar PaperProps={{backdropFilter: 'blur(10px)'}} sx={{height: '4em', background: theme.palette.mode === 'light' ? 'rgba(255,255,255,0.90)' : 'rgba(0,9,20,0.70)', backdropFilter: 'blur(50px)'}} variant='elevation' elevation={1} position='fixed'>
                <Toolbar variant="regular">
                    <IconButton  onClick={handleDrawerOpen} edge="start" size="large" sx={{ ml:0.5, mr:2}}>
                        <MenuRounded sx={{color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#ffffff'}} />
                    </IconButton>
                    <Hidden mdDown>
                        {
                            theme.palette.mode === 'light' ? <LogoFullHorizontal style={{height: '2.5em'}} /> : <SvgLogoFullHorizontalWhite style={{height: '2.5em'}} />
                        }
                    </Hidden>
                    <Navigation />
                    <Typography sx={{flexGrow:1, color: grey[900]}} align="center" variant="h6"></Typography>
                    <Tooltip title={`Switch to ${theme.palette.mode === 'light' ? 'Dark' : 'Light'} mode`}>
                        <IconButton onClick={toggleTheme}>
                            {
                                theme.palette.mode === 'light' ? <DarkModeRounded sx={{color: theme.palette.primary.main}} /> : <LightModeRounded sx={{color: '#ffffff'}} />
                            }
                        </IconButton>
                    </Tooltip>
                    <Box sx={{display:'flex'}}>
                        <IconButton size="large" onClick={handleMenuOpen}>
                            <AccountCircleRounded sx={{color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#ffffff'}} />
                        </IconButton>
                        <Menu
                            id="appbar-menu"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                variant: 'elevation',
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 4.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 12,
                                        height: 12,
                                        //borderTop: `1px solid ${theme.palette.text.secondary}`,
                                        //borderLeft: `1px solid ${theme.palette.text.secondary}`,
                                        bgcolor: `background.paper`,
                                        transform: `translateY(-50%) rotate(45deg)`,
                                        zIndex: 0
                                    }
                                }
                            }}
                        >
                            <MenuItem sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py:2, cursor: 'default'}}>
                                <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? 'hsl(32,100%,50%)' : 'hsl(32,100%,50%)' , border: `1px solid ${theme.palette.mode === 'dark' ? 'hsl(32,100%,50%)' : 'hsl(32,100%,50%)'}`, borderRadius: theme.spacing(2), fontWeight: 700, px: 4, py: 1, background: theme.palette.mode === 'dark' ? 'hsl(32 100% 50.1% / 0.2)' : 'hsl(32 100% 50.1% / 0.1)' }}>{userState.freeTrial ? 'Free Trial' : 'Premium'}</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem sx={{display: 'flex', justifyContent: 'center', py: 2, cursor: 'default'}}>
                                <Avatar sx={{backgroundColor: '#f36a5a'}}>
                                    {
                                        !(userState.userName && userState.userName.length > 0)  ? userState?.userName?.charAt(0) : <PersonRounded />
                                    }
                                </Avatar>
                            </MenuItem>
                            <Divider />
                            <MenuItem sx={{ cursor: 'default'}}>
                                <ListItemIcon>
                                    <PersonRounded sx={{mr:4, color: theme.palette.primary.light}} fontSize='small' />
                                </ListItemIcon>
                                {userState.userName}
                            </MenuItem>
                            <MenuItem sx={{ cursor: 'default'}}>
                                <ListItemIcon>
                                    <WorkRounded sx={{mr:4, color: theme.palette.primary.light}} fontSize="small" />
                                </ListItemIcon>
                                {userState.customer}
                            </MenuItem>
                            <Divider />
                            <MenuItem sx={{width: '12.5vw'}}>
                                <SettingsRounded sx={{mr: 4, color: theme.palette.primary.light}} fontSize='small' />
                                Preferences
                            </MenuItem>
                            <MenuItem onClick={logoutUser}>
                            <ListItemIcon>
                                <LogoutRounded sx={{mr:4, color: theme.palette.primary.light}} fontSize="small" />
                            </ListItemIcon>
                            Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )

}

export default Header