import * as Pages from '../../../pages'
import default_common from './common'
import default_dashboard from './dashboard'
import default_services from './services'

import common from './common'
import dashboard from './dashboard'
import * as services from './services'

export {
    common,
    dashboard,
    services
}

const ROUTES = [
    ...default_common,
    ...default_dashboard,
    ...default_services
]

export default ROUTES