import * as React from 'react'

import * as Services from './services'
import * as Dashboard from './dashboard'
import * as Login from './login'

const HomePageContainer = React.lazy(() => import('./home/PageContainer'))
const BudgetPageContainer = React.lazy(() => import('./budget/PageContainer'))
const NotFoundPageContainer = React.lazy(() => import('./not-found/PageContainer'))

export {
    HomePageContainer,
    BudgetPageContainer,
    NotFoundPageContainer,
    Services,
    Dashboard,
    Login
}