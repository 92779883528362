import * as Pages from '../../../../pages'
import default_aws from './aws'

import * as aws from './aws'

export { 
    aws
}

const ROUTES = [
    {
        link: '/services',
        title: 'Services',
        routeId: 5000,
        roles: ['services-viewer'],
        nested: true,
        component: null,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        nestedRoutes: [
            ...default_aws
        ]
    }
]

export default ROUTES