import * as Pages from '../../../../../../pages'

const ROUTES = [
    {
        link: '/services/aws/emr',
        title: 'EMR',
        routeId: 5002001,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true
    },
    {
        link: '/services/aws/emr/launch-cluster',
        title: 'Launch EMR Cluster',
        routeId: 5002002,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.LaunchInstancePageContainer,
        fullPageWidth: false,
        header: false,
        authenticated: true,
        sideNav: false,
        independent: false,
        external: false,
        routing: true
    }
]

export default ROUTES