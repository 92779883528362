import * as Pages from '../../../../pages'

const ROUTES = [
    {
        link: '/',
        title: 'CloudInvent Login',
        routeId: 1000,
        roles: ['viewer'],
        nested: false,
        component: Pages.Login.SignInPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: false,
        sideNav: false,
        independent: true,
        external: false,
        routing: true
    },
    {
        link: '/home',
        title: 'Home',
        routeId: 2000,
        roles: ['viewer'],
        nested: false,
        component: Pages.HomePageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: false,
        sideNav: false,
        independent: false,
        external: false,
        routing: true
    },
    {
        link: '/budget',
        title: 'Budget',
        routeId: 4000,
        roles: ['budget-admin', 'budget-viewer'],
        nested: false,
        component: Pages.BudgetPageContainer,
        fullPageWidth: false,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true
    },
]

export default ROUTES