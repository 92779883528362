import * as Pages from '../../../../../pages'
import ec2 from './ec2'
import emr from './emr'

export {
    ec2,
    emr
}

const ROUTES = [
    {
        link: '/services/aws',
        title: 'AWS',
        routeId: 5001,
        roles: ['services-viewer-aws'],
        nested: true,
        component: null,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        nestedRoutes: [
            ...ec2,
            ...emr
        ]
    }
]

export default ROUTES