import * as Pages from '../../../../../../pages'

const ROUTES = [
    {
        link: '/services/aws/ec2',
        title: 'EC2 Dashboard',
        routeId: 5001001,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
    },
    {
        link: '',
        title: 'Instances',
        routeId: 5001002,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: null,
        fullPageWidth: true,
        header: true,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: false,
    },
    {
        link: '/services/aws/ec2/view-instance',
        title: 'View Instances',
        routeId: 5001003,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true
    },
    {
        link: '/services/aws/ec2/launch-instance',
        title: 'Launch Instance',
        routeId: 5001004,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.LaunchInstancePageContainer,
        fullPageWidth: false,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true
    },
    {
        link: '/services/aws/ec2/instance-types',
        title: 'Instances Types',
        routeId: 5001005,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true
    },
    {
        link: '',
        title: 'Images',
        routeId: 5001006,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: null,
        fullPageWidth: true,
        header: true,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: false,
    },
    {
        link: '/services/aws/ec2/ami',
        title: 'AMIs',
        routeId: 5001007,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
    },
    {
        link: '',
        title: 'Network and Security',
        routeId: 5001008,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: null,
        fullPageWidth: true,
        header: true,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: false,
    },
    {
        link: '/services/aws/ec2/vpc',
        title: 'VPC',
        routeId: 5001009,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
    },
    {
        link: '/services/aws/ec2/security-groups',
        title: 'Security Groups',
        routeId: 5001010,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
    },
    {
        link: '/services/aws/ec2/key-pairs',
        title: 'Key Pairs',
        routeId: 5001011,
        roles: ['services-aws-ec2-viewer'],
        nested: false,
        component: Pages.Services.AWS.EC2.ViewInstancesPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
    },
]

export default ROUTES